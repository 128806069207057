"use strict";

var Nav = {

    selector: $('#nav'),

    init: function() {

        Nav.compile();

        Nav.layout();

        Nav.listen();

    },

    compile: function() {

        var source = $('#template-nav').html(),
            template = Handlebars.compile(source),
            html = template({ nav: data_nav });

        Nav.selector.append(html);

    },

    layout: function() {

        Nav.selector.find('.cols').each(function() {

            var $cols = $('.cols__col', this),
                cols_height = 0;

            $cols.each(function() {

                var col_height = $(this).outerHeight();

                col_height > cols_height ? cols_height = col_height : cols_height = cols_height;

            });

            $cols.css('height', cols_height);

        });

    },

    listen: function() {

        Nav.selector.on('mouseenter', '.nav__section', function(e) {

            var $section = $(this),
                $parent = $section.parent(),
                $cat = $section.siblings('.nav__cats'),
                $sections = Nav.selector.find('.nav__section'),
                $cats = Nav.selector.find('.nav__cats'),
                nav_class_active = 'nav__cats--show';

            if (!$section.hasClass(nav_class_active)) {

                $sections.removeClass(nav_class_active);
                $cats.removeClass(nav_class_active);

                $section.addClass(nav_class_active);
                $cat.addClass(nav_class_active);

            }

            $parent.on('mouseleave', function(e) {

                $parent.off();

                $section.removeClass(nav_class_active);
                $cat.removeClass(nav_class_active);

            });

        });

    }

};

var Search = {

    selector: $('#minisearch'),

    input: $('#keyword'),

    init: function() {

        var $container = Search.selector.find('.input'),
            keyword = Search.url('keyword');

        if (keyword) {

            Search.input.val(keyword);

        }

        Search.input.focusin(function() {

            $container.addClass('input--active');

        });

        Search.input.focusout(function() {

            $container.removeClass('input--active');

        });

    },

    url: function(parameter) {

        return decodeURIComponent((new RegExp('[?|&]' + parameter + '=' + '([^&;]+?)(&|#|;|$)').exec(window.location.search)||[,""])[1].replace(/\+/g, '%20')) || null;

    }

};

var MiniCart = {

    trigger: $('#minicart-trigger'),

    table: $('#minicart-table'),

    content: $('#minicart-content'),

    init: function() {

        MiniCart.hidden();

        MiniCart.status();

    },

    hidden: function() {

        MiniCart.trigger.on('mouseenter', function() {

            MiniCart.trigger.off();

            MiniCart.toggle();

            MiniCart.visible();

        });

    },

    visible: function() {

        MiniCart.table.on('mouseleave', function() {

            MiniCart.table.off();

            MiniCart.toggle();

            MiniCart.hidden();

        });

    },

    toggle: function() {

        MiniCart.trigger.toggleClass('active');

        MiniCart.table.toggleClass('active');

        Overlay.toggle(false);

    },

    status: function() {

        var rows = MiniCart.content.find('.Products_Basket_TBL tr'),
            count = rows.length;

        if (count === 0) {

            var empty = '<p class="msg padding bg--white font--medium border--top radius--bottom">Der er ingen varer i kurven.</p>';

            MiniCart.content.html(empty);

        } else {

            var $badge = MiniCart.trigger.children('.badge');

            $badge.show();

        }

    }

};

var ProductVariant = {

    selector: $('#js-variants'),

    init: function() {

        ProductVariant.check();

    },

    check: function() {

        var variantExists = ProductVariant.selector.find('select');

        if (variantExists.length) {

            ProductVariant.listen();

        } else {

            ProductVariant.selector.remove();

        }

    },

    listen: function() {

        var $select = ProductVariant.selector.find('select');

        $select.on('change', function() {

            //console.log('New variant chosen');

        });

        ProductVariant.selector.show();

    }

};

var Category = {

    selector: $('#category-description'),

    height: null,

    init: function() {

        if (Category.selector.length) {

            Category.description();

            Category.toggle();

        }

    },

    description: function() {

        var $paragraphs = Category.selector.find('p'),
            height_collapsed = 0,
            height_expanded = 0;

        $paragraphs.each(function(index) {

            var $this = $(this);

            if (index == 0) {

                height_collapsed = height_collapsed + $this.outerHeight(true);

            }

            Category.height = Category.height + $this.outerHeight(true);

        });

        Category.selector.css('height', height_collapsed);

    },

    toggle: function() {

        $('#category-description-expand').click(function(e) {

            e.preventDefault();

            $(this).remove();

            Category.selector.css('height', Category.height);

        });

    }

};

var Grid = {

    selector: $('#grid'),

    init: function() {

        Grid.prepare();

        Grid.hover();

        Grid.buy();

        Grid.layout();

        setTimeout(function(){

            Grid.isotope({
                itemSelector: '.product__container',
                layoutMode: 'fitRows',
                getSortData: {
                    index: '[data-index]',
                    name: '[data-name]',
                    price: function(item) {

                        var $this = $(item),
                            price = $this.data('price') ? parseInt($this.data('price'), 10) : false;

                        return price;

                    },
                    discount: function(item) {

                        var $this = $(item),
                            discount = $this.data('discount') ? parseInt($this.data('discount'), 10) : false;

                        return discount;

                    }
                },
                sortBy: 'index',
                sortAscending: true
            });

            Grid.render();

        }, 250);

    },

    prepare: function() {

        var $divs;

        $divs = Grid.selector.find('.ProductList_Custom_DIV > div');
        $divs.unwrap()

        $divs = Grid.selector.find('> div');
        $divs.removeClass('BackgroundColor1_Productlist BackgroundColor2_Productlist').addClass('product__container');

    },

    hover: function($container) {

        var hover_class = 'product--hover',
            $watch = $container ? $container : Grid.selector;

        $watch.find('.product__container').hover(function(){

            var $this = $(this);

            $this.addClass(hover_class);

        }, function(){

            var $this = $(this);

            $this.removeClass(hover_class);
 
        });

        // Replaced with the above due to errors/wierd behavior on mouseenter/mouseleave
        // Especially on products where third party scripts was injecting variants/sizes

        // $watch.on('mouseenter mouseleave', '.product__container', function() {

        //     var $this = $(this),
        //         state_hover = $this.hasClass(hover_class);
                
        //     if (state_hover) {

        //         $this.removeClass(hover_class);

        //     } else {

        //         $this.addClass(hover_class);

        //     }

        // });

    },

    buy: function() {

        Grid.selector.on('click', '.js-buy', function(e) {

            Grid.selector.off();

            e.preventDefault();

            var $form = $(this).closest('form');

            $form.submit();

        });

    },

    layout: function() {

        var $items = Grid.selector.find('.product__container:visible'),
            common_height = 0;

        $items.each(function(index) {

            var $this = $(this),
                height = $this.outerHeight();

            height > common_height ? common_height = height : common_height = common_height;

        });

        $items.css('height', common_height + 'px');

    },

    isotope: function(parameters) {

        Grid.selector.isotope(parameters);

    },

    render: function() {

        Grid.selector.isotope('on', 'layoutComplete', function(isoInstance, laidOutItems) {

            $('#count').text(laidOutItems.length);

            GridImages.render();

        });

    }

};

var GridItems = {

    selector: function($container) {

        return $container.find('.product__container');

    },

    vendors: [],
    types: [],
    parameters: [],

    element: null,
    data: null,

    init: function() {

        $('#count').text(GridItems.selector(Grid.selector).length);
        $('#total').text(GridItems.selector(Grid.selector).length);

        GridItems.loop(Grid.selector);

    },

    count: function($container) {

        return GridItems.selector($container).length;

    },

    loop: function($container) {

        var $items = GridItems.selector($container);

        GridItems.selector($container).each(function(index) {

            var $this = $(this),
                $data = $this.find('.data');

            GridItems.element = $this;

            var data_parameters = $data.children('.data-parameters').text(),
                data_sku = $data.children('.data-sku').text(),
                data_new = $data.children('.data-new').html(),
                data_stock = $data.children('.data-stock').text(),
                data_vendor = $data.children('.data-vendor').text(),
                data_link = $this.find('.name a');

            GridItems.data = {
                name: data_link.text(),
                sku: data_sku,
                details: (data_parameters != '') ? JSON.parse(data_parameters) : false,
                new: data_new ? true : false,
                stock: (data_stock.indexOf('kke') > -1) ? false : true,
                vendor: (data_vendor != '') ? data_vendor.toString() : false,
                link: data_link ? data_link.attr('href') : false,
                image: '/images/' + data_sku + '-1-t.jpg'
            };

            $data.remove();

            GridItems.setIndex(index);

            GridItems.setName();

            GridItems.setSku();

            GridItems.setVendor();

            GridItems.setLink();

            GridItems.setStock();

            GridItems.setImage();

            GridItems.setParameters();

            GridItems.setNew();

            GridItems.setPrice();

        });

    },

    setIndex: function(index) {

        GridItems.element.attr('data-index', index);

    },

    setName: function() {

        GridItems.element.attr('data-name', GridItems.data.name);

    },

    setSku: function() {

        GridItems.element.attr('data-sku', GridItems.data.sku);

    },

    setVendor: function() {

        if (GridItems.data.vendor) {

            GridItems.vendors.push(GridItems.data.vendor);

        }

    },

    setLink: function() {

        var $button = GridItems.element.find('.js-info');

        $button.attr('href', GridItems.data.link);

    },

    setImage: function() {

        var $img = GridItems.element.find('.img');

        $img.attr('data-echo', GridItems.data.image);

    },

    setStock: function() {

        var $actions = GridItems.element.find('.action'),
            html = '';

        if (GridItems.data.stock) {

            html += '<span class="stock instock"><span class="icon icon-circle"></span><span class="text">På lager</span></span>';

        } else {

            html += '<span class="stock"><span class="icon icon-circle"></span><span class="text">Ikke på lager</span></span>';

        }

        html = $(html);

        $actions.append(html);

    },

    setParameters: function() {

        if (GridItems.data.details) {

            if (GridItems.data.vendor) {

                GridItems.data.details.parameters.vendor = GridItems.data.vendor;

            }

            _.forIn(GridItems.data.details.parameters, function(value, key, obj) {

                if (_.contains(data_sortables, key)) {

                    var v = value;

                    if (_.isArray(v)) {

                        v = JSON.stringify(v);

                    }

                    GridItems.element.attr('data-' + key, v);

                    GridItems.parameters.push({

                        id: key,
                        value: value

                    });

                }

            });

            GridItems.types.push(GridItems.data.details.type);

        }

    },

    setNew: function() {

        if (GridItems.data.new) {

            var badge = '<div class="badge badge--new">Nyhed</div>';

            GridItems.element.find('.image').append(badge);

        }

    },

    setPrice: function() {

        var $price = GridItems.element.find('.Prices_Custom_DIV > div'),
            $price_before = $price.children('.price-before'),
            $price_now = $price.children('.price-now'),
            $price_special = $price.children('.price-special'),
            $price_currency = $price.children('.price-currency'),
            price_before = $price_before.text().trim(),
            price_now = $price_now.text().trim(),
            price_special = $price_special.text().trim() ? $price_special.text().trim() : false;

        if (price_special) {

            $price_before.text(price_now);

            price_before = price_now;

            $price_now.text(price_special);

            price_now = price_special;

            $price_special.remove();

        }        

        var price_before = price_before.replace(/(\.)/g,''),
            price_before = price_before.replace(/(\,)/g,''),
            price_now = price_now.replace(/(\.)/g,''),
            price_now = price_now.replace(/(\,)/g,''),
            class_special = 'price-special';

        if (price_before) {

            var saving = Math.round((1 - (price_now / price_before)) * 100),
                badge = '<div class="badge badge--offer">Spar ' + saving  + '%</div>';

            $price_now.addClass(class_special);
            $price_currency.addClass(class_special);

            GridItems.element.find('.image').append(badge);

            GridItems.element.attr('data-discount', saving);

        } else {

            $price_before.remove();

        }

        GridItems.element.attr('data-price', price_now);

        if (GridItems.data.new) {

            var $badge = GridItems.element.find('.badge--offer');

            $badge.css('left', 'auto').css('right', '10px');

        }

    }

};

var GridFilter = {

    selector: $('#filters'),

    vendor: _.uniq(GridItems.vendors),

    types: _.uniq(GridItems.types),

    parameters: [],

    isotope: [],

    init: function() {

        GridFilter.loopParameters();

        GridFilter.loopFilters();

        GridFilter.sort();

        GridFilter.compile();

        GridFilter.sliders();

        GridFilter.listen();

        GridFilter.select();

        GridFilter.reset();

    },

    loopParameters: function() {

        var keys = _.uniq(_.pluck(GridItems.parameters, 'id')),
            count = GridItems.count(Grid.selector),
            intersection = [];

        // Only show filters that 80% of all visible products have

        _.forEach(keys, function(key) {

            var test = _.where(GridItems.parameters, { id: key }).length,
                withinThreshold = ((test / count) > 0.8);

            if (withinThreshold) {

                intersection.push(key);

            }

        });

        // Create filters based on intersection

        _.forEach(GridItems.parameters, function(parameter) {

            var id = parameter.id,
                value = parameter.value;

            var key = _.findKey(GridFilter.parameters, function(obj) { return obj.id == id; });

            if (!key && _.contains(intersection, id)) {

                GridFilter.parameters.push({
                    id: id,
                    label: null,
                    values: _.isArray(value) ? value : [ value ]
                });

            } else if ( _.contains(intersection, id)) {

                if (value) {

                    var values = GridFilter.parameters[ key ].values;

                    if (_.isArray(value)) {

                        _.forEach(value, function(v) {

                            if (!_.contains(values, v)) {

                                GridFilter.parameters[ key ].values.push(v);

                            }

                        })

                    } else {

                        if (!_.contains(values, value)) {

                            GridFilter.parameters[ key ].values.push(value);

                        }

                    }

                }

            }

        });

    },

    loopFilters: function() {

        _.forEach(GridFilter.parameters, function(filter, index) {

            var values = filter.values;

            if (_.isNumber(filter.values[0])) {

                var sorted = values.sort(function(a, b) { return a - b; }),
                    slider = {
                        min: _.min(values),
                        max: _.max(values)
                    };


            } else {

                var sorted = values.sort(function (a, b) {

                        if (a > b) {

                            return 1;

                        }

                        if (a < b) {
                            
                            return -1;

                        }

                        return 0;

                    }),
                    slider = false;

            }

            GridFilter.parameters[ index ].label = data_parameters[ filter.id ].label;
            GridFilter.parameters[ index ].values = filter.values;
            GridFilter.parameters[ index ].slider = slider;

        });

    },

    sort: function() {

        GridFilter.collection = _.sortBy(GridFilter.parameters, 'label');

    },

    compile: function() {

        var source = $('#template-filters').html(),
            template = Handlebars.compile(source),
            html = template({

                filters: GridFilter.collection

            });

        GridFilter.selector.prepend(html);

    },

    sliders: function() {

        var $sliders = GridFilter.selector.find('.nouislider');

        $sliders.each(function() {

            var $this = $(this),
                $element = $this.closest('.filter__element'),
                id = $this.data('id'),
                label = $this.data('label'),
                min = $this.data('min'),
                max = $this.data('max'),
                interval = (max - min) / 10;

            $this.noUiSlider({
                start: [ min, max ],
                connect: true,
                step: interval,
                range: {
                    'min': min,
                    'max': max
                }
            });

            $this.on({

                change: function(obj, values) {

                    var key = _.findKey(GridFilter.isotope, { id: id }),
                        current_min = parseInt(values[0]),
                        current_max = parseInt(values[1]);

                    if (!key) {

                        GridFilter.isotope.push({
                            id: id,
                            label: label,
                            slider: true,
                            values: [ current_min, current_max ]
                        });

                    } else {

                        GridFilter.isotope[ key ].values = [ current_min, current_max ];

                    }

                    Grid.isotope({

                        filter: function() {

                            return GridFilter.filter(this);

                        }

                    });

                },
                slide: function(obj, values) {

                    var current_min = values[0],
                        current_max = values[1];

                    $('#' + id + '-min').text(current_min);
                    $('#' + id + '-max').text(current_max);

                    if (current_min == min && current_max == max) {

                        $element.closest('.filter__element').removeClass('active');

                    } else {

                        $element.closest('.filter__element').addClass('active');

                    }

                }

            });

        });

    },

    filter: function(item) {

        var $item = $(item),
            item_filters = [],
            item_show = false,
            i;

        if (!_.isEmpty(GridFilter.isotope)) {

            for(i = 0; i < GridFilter.isotope.length; i++) {

                var filter = GridFilter.isotope[ i ].id,
                    values = GridFilter.isotope[ i ].values,
                    slider = GridFilter.isotope[ i ].slider,
                    value = $item.data(filter);

                if (value && slider && _.isArray(values)) {

                    var min = values[0],
                        max = values[1],
                        inbetween = false,
                        k;

                    for(k = 0; k < value.length; k++) {

                        if (value[ k ] >= min && value[ k ] <= max) {

                            inbetween = true;

                        }

                    }

                    if (inbetween) {

                        item_filters.push(1);

                    } else {

                        item_filters.push(0);

                    }

                } else if (value && _.isArray(value)) {

                    var intersect = _.intersection(values, value);

                    if (!_.isEmpty(intersect)) {

                        item_filters.push(1);

                    } else {

                        item_filters.push(0);

                    }

                } else if (value && _.contains(values, value)) {

                    item_filters.push(1);

                } else {

                    item_filters.push(0);

                }

            }

            if (_.contains(item_filters, 0)) {

                item_show = false;

            } else {

                item_show = true;

            }

        } else {

            item_show = true;

        }

        return item_show;

    },

    show: function($filter_element, $filter_placeholder, $filter_body) {

        var $filter_reset = GridFilter.selector.find('.filter__reset');

        var top = $filter_element.outerHeight(),
            width = $filter_element.outerWidth() + 1;

        $filter_body
            .css('top', top)
            .css('width', width);

        Overlay.show(true);

        $filter_element.addClass('show');

        $filter_element.on('mouseleave', function() {

            $filter_element.removeClass('show');
            $filter_placeholder.off();
            $filter_body.off();

        });

        $filter_reset.addClass('show');

    },

    hide: function() {

        Overlay.hide(true);

        GridFilter.selector.find('.filter__element').removeClass('show');
        GridFilter.selector.find('.filter__reset').removeClass('show');

    },

    listen: function() {

        // Show/hide filter on mousenter and click

        GridFilter.selector.on('mouseenter', '.filter__element', function(e) {

            e.preventDefault();

            var $filter_element = $(this),
                $filter_placeholder = $filter_element.children('.filter__placeholder'),
                $filter_body = $filter_element.children('.filter__body');

            GridFilter.show($filter_element, $filter_placeholder, $filter_body);

        });

        GridFilter.selector.on('mouseleave', function(e) {

            e.preventDefault();

            GridFilter.hide();

        });

        GridFilter.selector.on('click', '.filter__placeholder', function(e) {

            e.preventDefault();

            var $filter_placeholder = $(this),
                $filter_element = $filter_placeholder.parent(),
                $filter_body = $filter_element.children('.filter__body');

            if (!$filter_element.hasClass('show')) {

                GridFilter.show($filter_element, $filter_placeholder, $filter_body);

            } else {

                GridFilter.hide();

            }

        });

        Overlay.selector(true).on('click', function(e) {

            e.stopPropagation();
            e.preventDefault();

            GridFilter.hide();

        });

    },

    select: function() {

        GridFilter.selector.on('click', '.filter__link', function(e) {

            e.preventDefault();

            var $this = $(this),
                $element = $this.closest('.filter__element'),
                filter_id = $this.data('id'),
                filter_value = $this.data('value'),
                filter_state = $this.hasClass('selected'),
                key = _.findKey(GridFilter.isotope, { id: filter_id });

            // Are we pushing or pulling a filter?

            if (!filter_state) {

                $this.addClass('selected');

                if (key) {

                    // If the obj exists, push a new value to it

                    GridFilter.isotope[ key ].values.push(filter_value);

                } else {

                    // Else, create the object, and push a new value to it

                    GridFilter.isotope.push({
                        id: filter_id,
                        values: [ filter_value ]
                    });

                    key = _.findKey(GridFilter.isotope, { id: filter_id });

                }

            } else {

                $this.removeClass('selected');

                // Remove value from the object

                _.remove(GridFilter.isotope[ key ].values, function(value) {

                    return value == filter_value;

                });

            }

            // Remove the object if there are no values

            if (_.isEmpty(GridFilter.isotope[ key ].values)) {

                GridFilter.isotope.splice(key, 1);

                $element.removeClass('active');

            } else {

                $('#' + filter_id + '-count').text(GridFilter.isotope[ key ].values.length);

                $element.addClass('active');

            }

            Grid.isotope({

                filter: function() {

                    return GridFilter.filter(this);

                }

            });

        });

    },

    reset: function() {

        var $reset = GridFilter.selector.find('.filter__reset'),
            height = GridFilter.selector.outerHeight() + 'px';

        $reset.css('line-height', height).css('height', height);

        $reset.on('click', function(e) {

            e.preventDefault();

            GridFilter.isotope = [];

            GridFilter.selector.find('.filter__element').removeClass('active');
            GridFilter.selector.find('.filter__link').removeClass('selected');

            GridFilter.selector.find('.nouislider').each(function() {

                var $this = $(this),
                    min = $this.data('min'),
                    max = $this.data('max');

                $this.val([ min, max ]);

            });

            GridSorting.selector.find('.js-parameter, .js-order').removeClass('active');

            GridSorting.selector.find('li:first-child .js-parameter, li:first-child .js-order').addClass('active');

            Grid.isotope({

                filter: function() {

                    return GridFilter.filter(this);

                },
                sortBy: 'name',
                sortAscending: true

            });

            Overlay.hide(true);

        });

    }

};

var GridSorting = {

    selector: $('#sorting'),

    init: function() {

        GridSorting.parameter();

        GridSorting.order();

    },

    parameter: function() {

        GridSorting.selector.on('click', '.js-parameter', function(e) {

            e.preventDefault();

            var $this = $(this),
                parameter = $this.data('sortby');

            Grid.isotope({

                sortBy: parameter

            });

            GridSorting.selector.find('.js-parameter').removeClass('active');

            $this.addClass('active');

            GridImages.render();

        });

    },

    order: function() {

        GridSorting.selector.on('click', '.js-order', function(e) {

            e.preventDefault();

            var $this = $(this),
                parameter = $this.data('sortorder'),
                ascending = (parameter == 'asc') ? true : false;

            Grid.isotope({

                sortAscending: ascending

            });

            GridSorting.selector.find('.js-order').removeClass('active');

            $this.addClass('active');

            GridImages.render();

        });

    }

};

var GridImages = {

    init: function() {

        echo.init({
            offset: 250,
            throttle: 250,
            callback: function (element, op) {

                var $this = $(element);

                $this.load(function() {

                    $this.css('opacity', 1);

                });

            }
        });

    },

    render: function() {

        echo.render();

    }

};

var ProductData = {

    selector: $('#data'),

    json: null,

    init: function() {

        // Parse JSON string from HTML

        var json = ProductData.selector.find('.data-json').text(),
            json = JSON.parse(json);

        // Add the vendor

        json[ 'vendor' ] = ProductData.selector.find('.data-vendor').text();

        // Add the SKU

        json[ 'sku' ] = ProductData.selector.find('.data-sku').text();

        // Add the name

        json[ 'name' ] = ProductData.selector.find('.data-name').text();

        // Add stock

        var stock = ProductData.selector.find('.data-stock').text(),
            instock = (stock.indexOf('kke') > -1) ? false : true;

        json[ 'stock' ] = instock;

        // Save

        ProductData.json = json;

        // Delete data

        ProductData.selector.remove();

    },

    get: function(property, inParameters) {

        if (!ProductData.json) return false;

        if (inParameters) {

            return ProductData.json.hasOwnProperty('parameters') && ProductData.json.parameters.hasOwnProperty(property) ? ProductData.json.parameters[property] : false;

        } else {

            return ProductData.json.hasOwnProperty(property) ? ProductData.json[property] : false;

        }

    }

};

var ProductDelivery = {

    selector: $('#js-delivery'),

    init: function() {

        if (ProductStock.status) {

            var html = '<span class="product__meta__delivery__partners"></span>';

            ProductDelivery.selector.append(html);

        } else {

            ProductDelivery.selector.html('Forvent l&aelig;ngere leveringstid end normalt.');

        }

    }

};

var ProductBuy = {

    selector: $('#js-buy'),

    init: function(selector) {

        var $button = (selector) ? $(selector) : $('#js-button');

        //if (ProductStock.status) {

        $button.click(function(e) {

            e.preventDefault();

            $(this).closest('form').submit();

        });

        ProductBuy.listen();

        //}

    },

    listen: function() {

        ProductBuy.selector.on('mouseenter', function() {

            ProductAmount.selector.addClass('product__price__button__amount--show');

        });

        ProductBuy.selector.on('mouseleave', function() {

            var val = ProductAmount.selector.val();

            if (val != 1) {

                ProductAmount.selector.addClass('product__price__button__amount--show');

            } else {

                ProductAmount.selector.removeClass('product__price__button__amount--show');

            }

        });

    }

};

var ProductStock = {

    selector: $('#js-stock'),

    status: null,

    init: function(selector) {

        ProductStock.status = ProductData.get('stock');

        var html;

        if (ProductStock.status) {

            ProductStock.selector.addClass('instock');

            html = '<span class="icon icon-circle"></span><span class="text">Varen er på lager</span>';

        } else {

            html = '<span class="icon icon-circle"></span><span class="text">Varen er ikke på lager</span>';

        }

        ProductStock.selector.html(html);

    }

};

var ProductAmount = {

    selector: $('#js-amount'),

    init: function(selector) {

        //f (ProductStock.status) {

            ProductAmount.listen();

        //} else {

            //ProductAmount.selector.hide();

        //}

    },

    listen: function() {

        ProductAmount.selector.on('change', function() {

            var val = parseInt(ProductAmount.selector.val());

            if (_.isNaN(val)) {

                ProductAmount.selector.val(1);

                $('#amount').val(1);

            } else {

                ProductAmount.selector.val(val);

                $('#amount').val(val);

            }

        });

    }

};

var ProductCountdown = {

    selector: $('#js-countdown'),

    element: {

        hours: null,
        minutes: null,
        and: null

    },

    init: function(selector) {

        if (ProductStock.status) {

            var now = moment(),
                day = now.day(),
                hour = now.hour();

            var weekend = (day === 0 || day === 6) ? true : false,
                afterhours = (hour < 8 || hour > 16) ? true : false;

            if (!weekend && !afterhours) {

                ProductCountdown.selector.show();

                ProductCountdown.start();

            }

        }

    },

    start: function() {

        var now = moment(),
            limit = moment().hours(16).minutes(0).seconds(0).millisecond(0),
            timeframe = limit.diff(now);

        ProductCountdown.element.hours = ProductCountdown.selector.find('.js-hours'),
        ProductCountdown.element.minutes = ProductCountdown.selector.find('.js-minutes'),
        ProductCountdown.element.and = ProductCountdown.selector.find('.js-and');

        ProductCountdown.selector.countdown(limit.format('YYYY/MM/DD HH:mm:ss')).on('update.countdown', function(event) {

            ProductCountdown.update(event);

        }).on('finish.countdown', function(event) {

            ProductCountdown.stop(event);

        });

    },

    update: function(event) {

        var hours = event.strftime('%-H');

        if (hours > 0) {

            ProductCountdown.element.hours.text(event.strftime('%-H time%!H:r;'));

        } else {

            ProductCountdown.element.hours.remove();

            ProductCountdown.element.and.remove();

        }

        ProductCountdown.element.minutes.text(event.strftime('%-M minut%!M:ter;'));

    },

    stop: function(event) {

        ProductCountdown.selector.remove();

    }

};

var ProductTabs = {

    selector: $('#js-tabs'),

    init: function() {

        var $nav = ProductTabs.selector.find('.tabs__nav');

        $nav.on('click', 'a', function(e) {

            e.preventDefault();

            var $this = $(this),
                id = $this.data('tab');

            ProductTabs.selector.find('.tabs__nav a').removeClass('active');

            $this.addClass('active');

            ProductTabs.show(id);

        });

    },

    show: function(tab) {

        var id = '#' + tab;
        
        ProductTabs.selector.find('.tabs__content').hide();

        ProductTabs.selector.find(id).fadeIn('fast');

    }

};

var ProductBrand = {

    init: function() {

        var name = ProductData.get('vendor');

        if (name) {

            name = name.toString().toLowerCase();

            ProductBrand.check(name).done(function(image) {

                if (image) {

                    $('#js-content').prepend(ProductBrand.html(image, name));

                }

            });

        }

    },

    check: function(name) {

        var def = $.Deferred();

        var path = '/images/brands/' + name + '.jpg';

         $.ajax({

            url: path,
            type: 'HEAD',
            error: function() {

                def.resolve(false);

            },
            success: function() {

                def.resolve(path);

            }

        }); 

        return def.promise();

    },

    html: function(image, name) {

        return '<div class="right margin--left margin--right margin--bottom"><img src="' + image + '" alt="' + name + '" /></div>';

    }

};

var ProductDetails = {

    selector: $('#js-details'),
    collection: [],

    init: function() {

        ProductDetails.make();

        ProductDetails.compile();

    },

    make: function() {

        _.forEach(data_groups[ ProductData.get('type') ], function(group, key) {

            var detail = {
                label: group.label,
                parameters: []
            };

            _.forEach(group.parameters, function(param, key) {

                var params = ProductData.get('parameters'),
                    param_value = params[ param ];

                if (param === 'vendor' || param === 'sku') {

                    var l = (param === 'vendor') ? 'Producent' : 'Varenummer',
                        v = ProductData.get(param);

                    if (v) {

                        detail.parameters.push({

                            label: l,
                            explanation: '',
                            values: v

                        });

                    }

                } else if (param !== 'youtubevideo' && param_value) {

                    detail.parameters.push({

                        label: data_parameters[ param ].label,
                        explanation: data_parameters[ param ].explanation,
                        values: param_value

                    });

                }

            });

            if (detail.parameters.length) {

                ProductDetails.collection.push(detail);

            }

        });

    },

    compile: function() {

        var source = $('#template-details').html(),
            template = Handlebars.compile(source),
            html = template({ details: ProductDetails.collection });

        ProductDetails.selector.append(html);

    }

};

var ProductPrice = {

    selector: $('#js-price'),

    init: function() {

        var $price = ProductPrice.selector.find('.Prices_Custom_DIV > div'),
            $price_before = $price.children('.price-before'),
            $price_now = $price.children('.price-now'),
            $price_currency = $price.children('.price-currency'),
            price_before = $price_before.text().trim(),
            price_now = $price_now.text().trim(),
            price_before = price_before.replace(/(\.)/g,''),
            price_before = price_before.replace(/(\,)/g,''),
            price_now = price_now.replace(/(\.)/g,''),
            price_now = price_now.replace(/(\,)/g,''),
            class_special = 'price-special';

        if (price_before) {

            var saving = Math.round((1 - (price_now / price_before)) * 100),
                badge = ProductPrice.badge(saving);
                
            $price_before.text($price_before.text().trim());

            $price_now.addClass(class_special);
            $price_currency.addClass(class_special);

            ProductPrice.selector.before(badge);

        } else {

            $price_before.remove();

        }

        $price_now.text($price_now.text().trim());

    },

    badge: function(saving) {

        return '<div class="product__discount"><div class="product__discount__inner"><span>Du sparer ' + saving + '%</span></div></div>';

    }

};

var ProductRelated = {

    selector: function(container) {

        return container ? $(container) : $('#js-related');

    },

    init: function(container) {

        ProductRelated.prepare(container);

    },

    prepare: function(container) {

        var $divs;

        // Remove DanDomain code <3

        if (container === '#js-related') {

            $divs = ProductRelated.selector(container).find('.Related_Custom_DIV > div');

        } else if (container === '#js-alsobought') {

            $divs = ProductRelated.selector(container).find('.CustomersAlsoBought_Custom_DIV > div');

        } else {

        	$divs = [];

        }

        if ($divs.length) {

	        $divs.unwrap()

	        // Add class for data extraction

	        $divs = ProductRelated.selector(container).find('.products > div');
	        $divs.addClass('product__container');

	        // Display items if they exist

            GridItems.loop(ProductRelated.selector(container));

            GridImages.init();

            Grid.hover(ProductRelated.selector(container));

            ProductRelated.buy(container);
        
            ProductRelated.slider(container);

            ProductRelated.selector(container).addClass('product__related--show');

        }

    },

    alignHeight: function(container) {

        var $divs = ProductRelated.selector(container).find('.products > .product__container'),
            height = 0;

        $divs.each(function(index) {

            var this_height = $(this).outerHeight(true);

            height = this_height > height ? this_height : height;

        });

        $divs.css('height', height + 'px');

    },

    slider: function(container) {

        var $slider = ProductRelated.selector(container).find('.slider');

        $slider.flexslider({

            selector: '.products > .product__container',
            animation: 'slide',
            slideshow: false,
            keyboard: false,
            controlNav: false,
            smoothHeight: false,
            animationLoop: false,
            controlsContainer: ProductRelated.selector(container).find('.slider-controls'),
            itemWidth: 275,
            itemMargin: 0,
            minItems: 4,
            prevText: '<span class="icon icon-left-open"></span>',
            nextText: '<span class="icon icon-right-open"></span>',
            start: function(slider) {

                ProductRelated.alignHeight(container);

                if (slider.count < 5) {

                    var $controls = ProductRelated.selector(container).find('.slider-controls');

                    $controls.remove();

                }

            }

        });

    },

    buy: function(container) {

        ProductRelated.selector(container).on('click', '.js-buy', function(e) {

            e.preventDefault();

            var $this = $(this),
                $product = $this.closest('.product__container'),
                sku = $product.data('sku');

            BuyRelatedProductCustom(sku, '1', '0');

        });

    }

};

var ProductBanner = {

    selector: $('#js-product-background'),

    hasBanner: null,

    path: '/images/',

    init: function() {

        var product_id = ProductVariantMasterID ? ProductVariantMasterID : ProductData.get('sku'),
            path = ProductThumbnails.path + product_id + '-banner.jpg';

        $.ajax({
            url: path,
            type: 'HEAD',
            error: function() {

                $('body').addClass('without-banner');

            },
            success: function() {

                ProductBanner.hasBanner = true;

                ProductBanner.load(path);

            }
        });

    },

    load: function(path) {

        // TODO: Start loading overlay

        var $img = $('<img style="display:none;" />').attr('src', path);

        $img.load(function() {

            ProductBanner.selector.css('background-image', 'url(' + path + ')');

            $('body').addClass('with-banner');

            // TODO: Stop loading overlay

        }).error(function() {

            // TODO: Stop loading overlay

        });

    }

};

var ProductImage = {

    selector: $('#js-image'),

    path: '/images/',

    group: [],

    init: function() {

        ProductImage.load(1);

        ProductImage.selector.on('click', 'a', function(e) {

            e.preventDefault();

            var id = $(this).children('img').data('id');

            ProductImage.popup(id);

        });

    },

    load: function(image_id) {

        var product_id = ProductVariantMasterID ? ProductVariantMasterID : ProductData.get('sku'),
            path = ProductImage.path + product_id + '-' + image_id + '.jpg',
            $img = $('<img style="display:none;" data-id="' + image_id + '" />').attr('src', path);

        $img.load(function() {

            ProductImage.selector.find('img').replaceWith($img);

            $img.fadeIn('fast');

        }).error(function() {

            var placeholder = '<div class="product__image__placeholder"><span class="icon-picture"></span></div>';

            ProductImage.selector.html(placeholder);

        });

    },

    popup: function(id) {

        var id = (id) ? id : 1;

        $.fancybox.open(ProductImage.group, {

            index: id - 1,
            padding: 40,
            margin: 40,
            helpers: {
                overlay : {
                    closeClick : true,
                    speedOut   : 200,
                    showEarly  : true,
                    locked     : false
                }
            },
            beforeLoad: function() {

                $.fancybox.showLoading();

            },
            afterLoad: function() {

                $.fancybox.hideLoading();

            }

        });

    }

};

var ProductThumbnails = {

    def: $.Deferred(),

    selector: $('#js-thumbnails'),

    path: '/images/',

    count: 1,

    limit: 1000,

    amount: 0,

    html: '',

    init: function() {

        ProductThumbnails.check();

        ProductThumbnails.def.promise().done(function() {

            ProductThumbnails.insert();

        });

    },

    check: function() {

        if (ProductThumbnails.count < ProductThumbnails.limit) {

            var makePath = function(version) {

                var product_id = ProductVariantMasterID ? ProductVariantMasterID : ProductData.get('sku'),
                    path = ProductThumbnails.path + product_id + '-' + ProductThumbnails.count + '-' + version + '.jpg';

                return path;

            };

            $.ajax({

                url: makePath('r'),
                type: 'HEAD',
                error: function() {

                    ProductThumbnails.limit = ProductThumbnails.count;

                    ProductThumbnails.amount = ProductThumbnails.count - 1;

                    ProductThumbnails.def.resolve();

                },
                success: function() {

                    var first = (ProductThumbnails.count == 1) ? ' active' : '';

                    ProductThumbnails.html += '<li><a class="js-thumbnail' + first + '" href="#" data-id="' + ProductThumbnails.count + '" rel="images"><img src="' + makePath('r') +'" /></a></li>';

                    ProductImage.group.push({

                        title: ProductData.get('name') + ' ' + ProductThumbnails.count,
                        href: makePath('p')

                    });

                    ProductThumbnails.count++;

                    ProductThumbnails.check();

                }

            });

        }

    },

    insert: function() {

        // Check if a video should be included in thumbnails

        var video_url = ProductData.get('youtubevideo', true);

        if (video_url) {

            ProductThumbnails.html = '<li><a href="' + video_url + '" class="js-thumbnail-video"><img src="/assets/images/icon-youtubevideo.png" /></a></li>' + ProductThumbnails.html;

            ProductThumbnails.amount++;

        }

        // If we have enough thumbnails, load their images, show them and enable slider.

        if (ProductThumbnails.amount > 1) {

            ProductThumbnails.html = '<ul class="product__thumbnails__slides">' + ProductThumbnails.html + '</ul>';

            ProductThumbnails.selector.prepend(ProductThumbnails.html);

            ProductThumbnails.selector.imagesLoaded().done(function() {

                ProductThumbnails.slider();

                ProductThumbnails.listen();

            });

        } else {

            ProductThumbnails.selector.remove();

        }

    },

    slider: function() {

        ProductThumbnails.selector.flexslider({

            namespace: 'flex-thumbs-',
            selector: '.product__thumbnails__slides > li',
            animation: 'slide',
            slideshow: false,
            keyboard: false,
            controlNav: false,
            smoothHeight: true,
            animationLoop: false,
            itemWidth: 90,
            itemMargin: 5,
            minItems: 4,
            prevText: '',
            nextText: '',
            start: function(slider) {

                if (slider.count < 4 && !ProductBanner.hasBanner) {

                    slider.addClass('flexslider-centered');

                };

                ProductThumbnails.selector.find('.product__thumbnails__cover').fadeOut('normal');

            }

        });

    },

    listen: function() {

        var $images = ProductThumbnails.selector.find('.js-thumbnail'),
            $videos = ProductThumbnails.selector.find('.js-thumbnail-video');

        if ($images.length) {

            $images.on('click', function(e) {

                e.preventDefault();

                if (ProductBanner.hasBanner) {

                    ProductImage.popup($(e.currentTarget).data('id'));

                } else {

                    ProductImage.load($(e.currentTarget).data('id'));

                    ProductThumbnails.selector.find('.js-thumbnail').removeClass('active');

                    $(this).addClass('active');

                }

            });

        }

        if ($videos.length) {

            $videos.fancybox({
                helpers: {
                    media: true,
                    overlay : {
                        closeClick : true,
                        speedOut   : 200,
                        showEarly  : true,
                        locked     : false
                    }
                }
            });

        }

    }

};

var ShopCart = {

    selector: $('#cart-container'),

    init: function() {

        var isCart = ShopCart.selector;

        if (isCart.length) {

            ShopCart.modifyDom();

            ShopCart.hideDiscount();

            ShopCart.replaceButtons();

        }

    },

    modifyDom: function() {

        var $divs = ShopCart.selector.find('.ShowBasket_Custom_DIV > div');

        if ($divs.length) {

            ShopCart.selector.find('.ShowBasket_Custom_DIV > div').unwrap();

            ShopCart.selector.find('.cart__list__element').unwrap();

        } else {

            $('#cart-additional').remove();
            $('#cart-summary').remove();

            var message = '<p>Der er ingen varer i kurven.</p>';

            $('#cart-list').replaceWith(message);

        }

    },

    hideDiscount: function() {

        var $discount = $('#discount'),
            $text = $discount.find('.cart__summary__text');

        if (!$text.text()) {

            $discount.remove();

        }

    },

    replaceButtons: function() {

        // Increase

        var $increase = ShopCart.selector.find('.cart__product__increase img');

        $increase.replaceWith('<span class="icon icon-plus"></span>');

        // Decrease

        var $decrease = ShopCart.selector.find('.cart__product__decrease img');

        $decrease.replaceWith('<span class="icon icon-minus"></span>');

        // Remove

        var $remove = ShopCart.selector.find('.cart__product__remove img');

        $remove.replaceWith('<span class="icon icon-cancel"></span>');

        // Order

        var $order = ShopCart.selector.find('.Basket_Order'),
            $img = ShopCart.selector.find('.Basket_Order img');

        $order.addClass('btn btn--gigantisch btn--green');

        $img.replaceWith('<span class="text">Til kassen</span>');

    }


};

var SubCategory = {

    selector: $('.SubCats_Categories_DIV', '#container'),

    children: $('.SubCats_Categories_DIV > div', '#container'),

    init: function() {

        if (SubCategory.selector.length) {

            SubCategory.modifyDom();

            SubCategory.grid();

            SubCategory.height();

            SubCategory.watch();

        }

    },

    modifyDom: function() {

        SubCategory.children.addClass('subcategory');

    },

    grid: function() {

        var i = 1;

        SubCategory.children.each(function(index) {

            if (i % 5 == 0) {

                $(this).addClass('last');

            }

            if (i == SubCategory.children.length) {

                $(this).remove();

            }

            i++;

        });

    },

    height: function() {

        var height = 0;

        SubCategory.selector.imagesLoaded(function() {

            SubCategory.children.each(function(index) {

                var this_height = $(this).outerHeight(true);

                height = this_height > height ? this_height : height;

            });

            SubCategory.children.css('height', height);

        });

    },

    watch: function() {

        SubCategory.children.hover(function() {

            $(this).addClass('subcategory--hover');

        }, function() {

            $(this).removeClass('subcategory--hover');

        });

    }

};

var ShopCheckout = {

    selector: $('#checkout-container'),

    init: function() {

        var isCheckout = ShopCheckout.selector;

        if (isCheckout.length) {

            ShopCheckout.modifyDom();

            ShopCheckout.clean();

            ShopCheckout.watch();

            ShopCheckout.replaceButtons();

        }

    },

    modifyDom: function() {

        var $payment_shipping = ShopCheckout.selector.find('#column-shipping-payment'),
            frame = '';

        // Modify DOM so we're able to style as needed.
        // Needs to be modified when (if) DanDomain allows one to modify the checkout template.

        $payment_shipping.addClass('cols cols--2');

        $payment_shipping.find('> h2:first, #payment-methods, #payment-methods-sencondary').wrapAll('<div id="shipping" class="cols__col"></div>');

        $payment_shipping.find('> h2:first, #shipping-methods').wrapAll('<div id="payment" class="cols__col"></div>');

        ShopCheckout.selector.find('#column-data > h3').prependTo('#customer-data');

        ShopCheckout.selector.find('#column-data, #column-shipping-payment').wrapAll('<div id="column-order-information"></div>');

        // Hide country selection

        var $countries = $('#checkout_customer_country,#checkout_customer_altdelivery_country').parent();

        $countries.css('position','absolute').css('left','-999em');

    },

    clean: function() {

        // Remove navigation

        Nav.selector.html('<li>&nbsp;</li>');

        // remove search

        Search.selector.remove();

        // Remove cart/trigger

        MiniCart.trigger.remove();

        // Replace confirm button

        var button = '<input type="image" id="confirm-complete-button" onclick="processOrder()" src="http://test.coolerkit.dk/images/filer/icon-accept-order.png">';

        $('#confirm-checkout').html(button);

    },

    watch: function() {

        var $container = $('#checkout-container'),
            $summary = $('#column-order-summary'),
            $information = $('#column-order-information'),
            container_pos = $container.position().top,
            summary_pos = $summary.position().top,
            original_position = container_pos + summary_pos,
            margin = 0,
            current = 0;

        $(window).scroll(function() {

            clearTimeout($.data(this, 'scrollCheck'));

            $.data(this, 'scrollCheck', setTimeout(function() {

                var top  = window.pageYOffset || document.documentElement.scrollTop;

                if (top >= original_position) {

                    var difference = $summary.outerHeight(true) - $information.outerHeight(true);

                    if (difference < 0 && margin) {

                       margin = (top - original_position);

                    } else {

                        if (((top - original_position) + $summary.outerHeight()) > $information.outerHeight(true)) {

                            margin = $information.outerHeight(true) - $summary.outerHeight();

                        } else {

                            margin = (top - original_position);

                        }

                    }

                } else {

                    margin = 0;

                }

                $summary.css('margin-top', margin);

            }, 250));

        });

    },

    replaceButtons: function() {

        // Order

        var $checkout = ShopCheckout.selector.find('#confirm-checkout'),
            $button = '<a id="confirm-complete-button" onclick="processOrder()" class="btn btn--green btn--gigantisch font--center"><span class="text">til betaling</span></a>';

        $checkout.html($button);

    }

};

var Overlay = {

    selector: function(grid) {

        return grid ? $('#overlay-grid') : $('#overlay');

    },

    show: function(grid) {

        Overlay.selector(grid).addClass('overlay--show');

    },

    hide: function(grid) {

        Overlay.selector(grid).removeClass('overlay--show');

    },

    toggle: function(grid) {

        Overlay.selector(grid).toggleClass('overlay--show');

    }

};